import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import Display from '../Display';
import ScreenHeader from '../ScreenHeader';

function Login() {

    const navigate = useNavigate();

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [todayDate, setTodayDate] = useState(new Date());
    
    useEffect(() => {
        setIsLoggedIn(false);
    }, []);


    const handleFormSubmit = (event) => {
        event.preventDefault();
    
        let form = event.target.form;
        //console.log("form: " + form);
    
        let formData = new FormData(form);
        console.log("formData is " + formData);

        //formData.append("todayDate", todayDate.toISOString());
    
        // Now create a form object with key/value pairs
        let formDataObj = Object.fromEntries(formData.entries());
        console.log("formDataObj is " + formDataObj);

        let formJSON = JSON.stringify(formDataObj);
        console.log("formJSON is " + formJSON);
    
        const endPoint = "http://localhost:3001/api/login";
        //const endPoint = "https://webhook.site/e0f3501a-7dce-4061-83ee-d26c769ea29f";
        //const endPoint = "https://visitor-trainer-cqckd6ced3a0bgch.centralus-01.azurewebsites.net/api/login";
    
        fetch(
          endPoint,
          {
            method: 'POST',
            //mode: 'no-cors',  //Cross-origin resource sharing; this setting only for dev to work across domains.
            headers: {
              'Accept': 'application/json',
              'Content-type': 'application/json',
            },
            body: formJSON
          } 
          
        ).then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json(); // Assuming API returns JSON data
        })
        .then(data => {
          // Handle the response data
          console.log(data);
        })
        .catch(error => {
          // Handle errors
          console.error('There was a problem with the fetch operation: ', error);
        });
    
        // can now validate
        /*
        if(formDataObj.name === '') {
          alert("Name cannot be blank.");
        }
        */
        console.log("Reached point 231bh.");
        //navigate("/acknowledge/Lone Tree");
    
        //alert(`The email you entered was: ${email}`);    
    
    };//handleFormSubmit


    if (isLoggedIn) {
        console.log("Reached isLoggedIn yay.");
        //return <Navigate to="/Acknowledge/Lone Tree" />;
    
        
    } else {
        return (

            <div style={{ textAlign: 'center'}}>
                {/*<form method="post" action={"https://visitor-trainer-cqckd6ced3a0bgch.centralus-01.azurewebsites.net/api/register"}>*/}
                {/*<form method="post" action={"https://webhook.site/e0f3501a-7dce-4061-83ee-d26c769ea29f"}>*/}
                
                <form>
                    <Table align='center' striped bordered hover cellPadding={2}>
                        <tbody>
                        <tr>
                            <td />
                            <td style={{maxWidth: 850}}>
                            <ScreenHeader message={"Visitor Training Tracker"}/>
                            </td>
                            <td />
                        </tr>
                        </tbody>
                    </Table>
                    <Table align='center' striped bordered hover cellPadding={1}>
                        <tbody>
                            <tr>
                                <td />
                                <td style={{maxWidth: 850}}>
                                    <h2>Please Login</h2>
                                </td>
                                <td />
                            </tr>
                        </tbody>
                    </Table>
                    <Table align='center' striped bordered hover cellPadding={5}>
                        <tbody>
                            <tr>
                                <td />
                                <td style={{maxWidth: 850}}>
                                    <br/>
                                    <br/>
                                    <Display message={`Need to register?`}/>
                                    <Link to="/register">Register here</Link>
                                </td>
                                <td />
                            </tr>
                        </tbody>
                    </Table>
                    <br />
                    <Table align='center' striped bordered hover cellPadding={5}>
                        <tbody>
                            <tr>
                                <td colSpan={3}>
                                    <div>
                                        <Table align='center' striped bordered hover cellPadding={5}>
                                            <tbody>
                                                <tr>
                                                    <td width={"40%"} align="right">
                                                        <label>
                                                            Email:
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="text"
                                                            value={email}
                                                            name="email" 
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            //value={inputs.email || ""}
                                                            //onChange={handleChange}
                                                            width={"200px"}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width={"40%"} align="right">
                                                        <label>
                                                            Password:
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="password"
                                                            name="password"
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            //value={inputs.last_name || ""}
                                                            //onChange={handleChange}
                                                            width={"200px"} 
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <br/>
                    <button onClick={handleFormSubmit}>Submit</button>
                </form>
                <br/>
                {/*message={`Submit`}*/}
            </div>

        )
    }

};

export default Login;



