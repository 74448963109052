import React, { useState } from 'react';
import logo from './i80logo.png';

const Logo = (props) => {
    //const [count, setCount] = React.useState(0);
    const styles = {
      backgroundColor: "hsl(0, 0%, 0%)",
      color: "gold",
      padding: "10px 20px",
      borderRadius: "5px",
      border: "none",
      cursor: "pointer",
    }
  
    return (
      <div>
        <img width={150} height={150} src={logo} alt="I-80 Gold logo" />
      </div>
    );
  }

export default Logo;