import React, { useState } from 'react';
import { Link } from "react-router-dom"; 
import { NavDropdown, Table } from 'react-bootstrap';
import { useActionState } from 'react';


const TextInput = (props) => {
    const styles = {
      backgroundColor: "hsl(0, 0%, 22%)",
      color: "gold",
      padding: "10px 20px",
      borderRadius: "5px",
      border: "none",
      cursor: "pointer",
      width: "130px"
    }
  
    return (
      <div>
        <Table align='center' cellPadding={5}>
          <tbody>
            <tr>
              <td align="right">
                <label>
                  Jane Doe Newsom
                </label>
              </td>
              <td>
                <input type="checkbox" name="acknowledge_flag" />
              </td>
            </tr>
            <tr>
              <td align="right">
                <label>
                  Steven McAllister
                </label>
              </td>
              <td>
                <input type="checkbox" name="acknowledge_flag" />
              </td>
            </tr>
            <tr>
              <td align="right">
                <label>
                  John Doe Wilburg
                </label>
              </td>
              <td>
                <input type="checkbox" name="acknowledge_flag" />
              </td>
            </tr>
            <tr>
              <td align="right">
                <label>
                  Brian Smith
                </label>
              </td>
              <td>
                <input type="checkbox" name="acknowledge_flag" />
              </td>
            </tr>
          </tbody>
        </Table>
       </div>
    );
  }

export default TextInput;


/*
 const [inputs, setInputs] = useState({});
  
    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, [name]: value}))
    }
  
    const handleSubmit = (event) => {
      event.preventDefault();
      alert(inputs);
    }

    function Submit() {
      const [state, formAction] = useActionState(submitForm, initialState);
      return
        (
          <button type="submit" disabled={pending}>
            { pending ? "Submitting..." : "Submit" }
          </button>
        );
    }

    function Form(action) {
      return (
        <form action={action}>
          <Submit />
        </form>
      );
    }
    */