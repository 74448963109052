import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import Button from './Button';

const ButtonTable = (props) => {
    const [counter, setCounter] = useState(0);
    const incrementCounter = (incrementValue) => setCounter(counter + incrementValue);
    return (
        <Table align='center' striped bordered hover cellPadding={5}>
            <tbody>
            <tr>
                <td>
                    <Button message={`Lone Tree`}/>
                </td>
                <td>
                    <Button message={`McCoy Cove`}/>
                </td>
            </tr>
            <tr>
                <td>
                    <Button message={`Ruby Hill`}/>
                </td>
                <td>
                    <Button message={`Granite Creek`}/>
                </td>
            </tr>
         </tbody>
        </Table>
    );
}

export default ButtonTable;