import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { BrowserRouter as Router, useNavigate, Routes, Route, Link } from "react-router-dom"; 
import ScreenHeader from '../ScreenHeader';
import Display from '../Display';
import CenteredTextContainer from '../CenteredTextContainer';
import Button from '../Button';
import TextInput from '../TextInput';
import DateTime from '../utils/DateTime';
// ... import other pages

const postData = (event) => {

  event.preventDefault();

  var vttForm = new FormData(document.getElementById("vttForm"));
  var first_name = vttForm.get("first_name");
  var last_name = vttForm.get("last_name");
  var email = vttForm.get("email");
  var phone = vttForm.get("phone");
  //var recorded_datetime = dateTime();
  //var todayDate: Date().toISOString(); //Convert date to ISO string
  //var acknowledge_flag = 1;


  const jsonString = JSON.stringify(vttForm);

  console.log(jsonString);

  const fetchData = async () => {
    try {

    //let result = fetch("https://visitor-trainer-cqckd6ced3a0bgch.centralus-01.azurewebsites.net/api/records", {
      const options = {
      method: 'POST',
      mode: 'no-cors',  //Cross-origin resource sharing
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone,
        //recorded_datetime: recorded_datetime,
        //ackowledge_flag: acknowledge_flag
      })
    };

    console.log(
        JSON.stringify(
          {
            first_name: first_name,
            last_name: last_name,
            email: email,
            phone: phone
          }
        )
    );

    const response = await fetch("https://webhook.site/5fbb042c-2885-4d73-8e85-a87f25eccb05", options);

    if (!response.ok) {
      throw new Error('Did not get a response from the server');
    }

    const data = await response.json();
    // Now set the variables or go to the next page?


    //console.log('Result: ' + result);
  
    } catch (error) {
      console.error('Error', error);
    }
  }// async fetchState
}//postData


/*
const navToSuccess = () => {
  const navigate = useNavigate();
  navigate('/success');
}
*/


function Acknowledge() {

  const params = useParams();

  return (
    <div style={{ textAlign: 'center'}}>
      {/*<form method="post" action={"https://visitor-trainer-cqckd6ced3a0bgch.centralus-01.azurewebsites.net/api/records"}>*/}
      <form id="vttForm" method="post" action={"https://webhook.site/5fbb042c-2885-4d73-8e85-a87f25eccb05"}>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td />
                <td style={{maxWidth: 850}}>
                <ScreenHeader message={"Visitor Training Tracker"}/>
                </td>
              <td />
            </tr>
          </tbody>
        </Table>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td />
                <td style={{maxWidth: 850}}>
                  <h1>Site: {params.siteID}</h1>
                </td>
              <td />
            </tr>
          </tbody>
        </Table>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td />
                <td style={{maxWidth: 850}}>
                  <CenteredTextContainer/>
                </td>
              <td />
            </tr>
          </tbody>
        </Table>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td colSpan={3}>
                <div>
                  <TextInput/>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td />
              <td style={{maxWidth: 850}}>
                <br/>
                <br/>
                  <Display message={`I have read and do understand the above statement completely. I will follow the directions given.`}/>
              </td>
              <td />
            </tr>
          </tbody>
        </Table>
        <br/>
        <label>
          I Agree: <input type="checkbox" name="acknowledge_flag" />
        </label>
        <br/><br/>
        
        <button onClick={ postData } >Submit</button>
        {/*<button type="submit" onSubmit={ navToSuccess }>Submit</button>*/}
      </form>
      <br/>
      <br/>
        {/*message={`Submit`}*/}
    </div>
  );
}

export default Acknowledge;


/*
<script>
function getCurDateTime() {
  var now = moment().format(); // Format the moment as a string
  var momentInput = document.getElementById("currentMoment");
  momentInput.value = now;
}
</script>

</head>

<body>

<div class="container">

<h1>Acknowledgment Form</h1>

<form action="api/records" method="POST" onsubmit="return getCurDateTime();">

<div class="form-group">

  <label for="email">Email</label>

  <input type="text" class="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter Email" name="email">

</div>

<div class="form-group">

  <label for="phone">Phone</label>

  <input type="text" class="form-control" id="phone" aria-describedby="emailHelp" placeholder="Enter Phone" name="phone">

</div>

<div class="form-group">

    <label for="first_name">First Name</label>

    <input type="text" class="form-control" id="first_name" aria-describedby="emailHelp" placeholder="Enter First Name" name="first_name">

</div>

<div class="form-group">

    <label for="last_name">Last Name</label>

    <input type="text" class="form-control" id="last_name" aria-describedby="emailHelp" placeholder="Enter Last Name" name="last_name">

</div>

<div class="form-group">

  <input type="hidden" class="form-control" id="ackowledge_flag" aria-describedby="emailHelp" value="1" name="ackowledge_flag">

</div>

<div class="form-group">

  <input type="hidden" class="form-control" id="currentMoment" aria-describedby="emailHelp" value="" name="recorded_datetime">

</div>

<button type="submit" class="btn btn-primary">Submit</button>
*/