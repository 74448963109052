import React, { useState } from 'react';
import { Link } from "react-router-dom"; 

const Button = (props) => {
    const styles = {
      backgroundColor: "hsl(0, 0%, 22%)",
      color: "gold",
      padding: "10px 20px",
      borderRadius: "5px",
      border: "none",
      cursor: "pointer",
      width: "130px"
    }
  
    return (
      <div>
        <a href="./">
          <button style={styles}>
            {props.message}
          </button>
        </a>
      </div>
    );
  }

export default Button;