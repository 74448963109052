import React from 'react';


const Display = (props) => {
    return (
        <div>
            {props.message}
        </div>
    );
}

export default Display;