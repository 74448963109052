import React, { useState, useEffect } from 'react';

function DateTime() {
    //useState and useEffect from React are used for managing state and side effects.
    //useState is used to create a state variable dateTime initialized with the current date and time using new Date().
    const [dateTime, setDateTime] = useState(new Date());

    //The setInterval function calls a function to update the state with the current date and time.
    //The useEffect hook is used to set up a timer that updates the dateTime state variable every second.
    useEffect(() => {
      const intervalId = setInterval(() => {
        setDateTime(new Date());
      }, 1000); // update every second

      //The cleanup function (clearInterval) clears the interval when the component unmounts to prevent memory leaks.
      return ()=> clearInterval(intervalId);
    }, []);

    return (
      <div>
        {dateTime.toLocaleString()}
      </div>
    );
   }

   export default DateTime;