import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
  
  const CenteredTextContainer = (props) => {
    const [count, setCount] = React.useState(0);
    
    return (
      <Container>
          <Row>
            <Col xs={100}>
            
                <div style={{ alignContent: 'left'}}>
                  <h2>ACKNOWLEDGMENT:</h2>
                  <div style={{ alignContent: 'left' }}>
                    <ul style={{ textAlign: 'left'}}>
                      <li>I understand that this training does not allow work activities on site.</li>
                      <li>I understand that I shall follow all rules and guidelines set by my escort.</li>
                      <li>I understand that I must wear the proper PPE (Personal Protective Equipment) required for the work area I will be visiting. PPE can be provided if needed.</li>
                      <li>Vehicles entering i-80 sites are required to undergo inspection and follow all traffic rules.</li>
                      <li>I understand that no photographs may be taken without permission from site management.</li>
                      <li>I understand that no items may be removed from site without permission from site management.</li>
                      <li>I understand that I will stay with my designated escort for the entirety of my visit.</li>
                    </ul>
                    <h3>All minors must be authorized by parent/guardian prior to arrival to the mine site.</h3>
                  </div>
                </div>
             
            </Col>
          </Row>
        </Container>
    );
  }

  export default CenteredTextContainer;



  

