import React, { useState } from 'react';
import ScreenHeader from '../ScreenHeader';
import Display from '../Display';
import Button from '../Button';
import Logo from '../Logo';
import ButtonTable from '../ButtonTable';
// ... import other pages


function SelectLocation() {

  return (
    <div style={{ textAlign: 'center' }}>
      <ScreenHeader message={"Visitor Training Tracker"}/>
      <br/><br/>
      <Logo/>
      <br/>
      <Display message={`Select the location of your visit:`}/>
      <br/>
      <div>
        <ButtonTable />
      </div>
      <Display message={`or`}/>
      <br/>
      <Button message={`View My Training`}/>
    </div>
    
  );
}

export default SelectLocation;

  