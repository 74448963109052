import React, { useState } from 'react';
import ScreenHeader from './ScreenHeader';
import Display from './Display';
import CenteredTextContainer from './CenteredTextContainer';
import Button from './Button';

// About.js
function About() {
    const [counter, setCounter] = useState(0);
    const incrementCounter = (incrementValue) => setCounter(counter + incrementValue);
    return (
      <div style={{ textAlign: 'center' }}>
        <ScreenHeader message={"Visitor Training Tracker"}/>
        <br></br><br></br>
        <Button onClickFunction={incrementCounter} increment = {1}/>
        <Button onClickFunction={incrementCounter} increment = {5}/>
        <Button onClickFunction={incrementCounter} increment = {10}/>
        <Button onClickFunction={incrementCounter} increment = {100}/>
        <Display message={counter}/>
        <CenteredTextContainer/>
      </div>
    );
  }

  export default About;
  
  