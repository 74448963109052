import React from 'react';
import { useParams } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { BrowserRouter as Router, useNavigate, Routes, Route, Link } from "react-router-dom"; 
import ScreenHeader from '../ScreenHeader';
import Display from '../Display';
import CenteredTextContainer from '../CenteredTextContainer';
import Button from '../Button';
import TextInput from '../TextInput';
import DateTime from '../utils/DateTime';
// ... import other pages


function Success() {

  return (
    <div style={{ textAlign: 'center'}}>
      
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td />
                <td style={{maxWidth: 850}}>
                <ScreenHeader message={"Visitor Training Tracker"}/>
                </td>
              <td />
            </tr>
          </tbody>
        </Table>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td />
                <td style={{maxWidth: 850}}>
                  <CenteredTextContainer/>
                </td>
              <td />
            </tr>
          </tbody>
        </Table>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td colSpan={3}>
                <div>
                  <TextInput/>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td />
              <td style={{maxWidth: 850}}>
                <br/>
                <br/>
                  <Display message={`I have read and do understand the above statement completely. I will follow the directions given.`}/>
              </td>
              <td />
            </tr>
          </tbody>
        </Table>
        <br/>
        <label>
          I Agree: <input type="checkbox" name="acknowledge_flag" />
        </label>
        <br/><br/>
        
      <br/>
      <br/>
        {/*message={`Submit`}*/}
    </div>
  );
}

export default Success;

