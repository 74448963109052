import React, { useState } from 'react';
import { Link } from "react-router-dom"; 
import { NavDropdown, Table } from 'react-bootstrap';
import { useActionState } from 'react';
import DateTime from './utils/DateTime';


const TextInput = (props) => {
    const styles = {
      backgroundColor: "hsl(0, 0%, 22%)",
      color: "gold",
      padding: "10px 20px",
      borderRadius: "5px",
      border: "none",
      cursor: "pointer",
      width: "130px"
    }

    const [isMinor, setIsMinor] = useState(false);

    const handleToggleForm = () => {
      setIsMinor(!isMinor);
    };
  
    return (
      <div>
          <Table>
            <tbody>
              <tr>
                <td align="right">
                  <label>
                      This visitor is a minor (under 18):
                  </label>
                </td>
                <td>
                  <input 
                    type="checkbox"
                    onChange={handleToggleForm}
                    name="minorFlag" 
                    //value={inputs.first_name || ""}
                    //onChange={handleChange}
                  />
                </td>
              </tr>
            </tbody>
        </Table>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            {
                isMinor &&
                  <tr>
                    <td width={"40%"} align="right">
                      <label>
                        First Name:
                      </label>
                    </td>
                    <td>
                      <input 
                        type="text"
                        name="first_name" 
                        //value={inputs.first_name || ""}
                        //onChange={handleChange}
                        width={"200px"} 
                      />
                    </td>
                  </tr>
              }
              
              <tr>
                <td width={"40%"} align="right">
                  <label>
                    Last Name:
                  </label>
                </td>
                <td>
                    <input 
                      type="text"
                      name="last_name" 
                      //value={inputs.last_name || ""}
                      //onChange={handleChange}
                      width={"200px"} 
                    />
                </td>
              </tr>
              <tr>
                <td width={"40%"} align="right">
                  <label>
                      Email:
                  </label>
                </td>
                <td>
                  <input 
                      type="text"
                      name="email" 
                      //value={inputs.email || ""}
                      //onChange={handleChange}
                      width={"200px"} 
                    />
                </td>
              </tr>
              <tr>
                <td width={"40%"} align="right">
                  <label>
                      Phone:
                  </label>
                </td>
                <td>
                  <input 
                      type="text"
                      name="phone" 
                      //value={inputs.phone || ""}
                      //onChange={handleChange}
                      width={"200px"} 
                    />
                </td>
              </tr>
              <tr>
                <td width={"40%"} align="right">
                  <label>
                      Organization:
                  </label>
                </td>
                <td>
                  <input 
                      type="text"
                      name="org" 
                      //value={inputs.phone || ""}
                      //onChange={handleChange}
                      width={"200px"} 
                    />
                </td>
              </tr>
          </tbody>
        </Table>
      </div>
    );
  }

export default TextInput;


/*
 const [inputs, setInputs] = useState({});
  
    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, [name]: value}))
    }
  
    const handleSubmit = (event) => {
      event.preventDefault();
      alert(inputs);
    }

    function Submit() {
      const [state, formAction] = useActionState(submitForm, initialState);
      return
        (
          <button type="submit" disabled={pending}>
            { pending ? "Submitting..." : "Submit" }
          </button>
        );
    }

    function Form(action) {
      return (
        <form action={action}>
          <Submit />
        </form>
      );
    }
    */