import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { BrowserRouter as Router, useParams, Routes, Route, Link } from "react-router-dom"; 
import ScreenHeader from '../ScreenHeader';
import Display from '../Display';
import CenteredTextContainer from '../CenteredTextContainer';
import Button from '../Button';
import RegisterFormElements from '../utils/RegisterFormElements';
// ... import other pages


function RegisterPage() {

  //const params = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [org, setOrg] = useState("");
  const [todayDate, setTodayDate] = useState(new Date());
  //const [agreeFlag, setAgreeFlag] = useState(0);


  const handleFormSubmit = (event) => {

    // Default behavior of a form is to collect the values and send somewhere.
    // We want to stop the default behavior of submitting the page to itself (which refreshes the page) 
    event.preventDefault();

    /*
    console.log(event.target.org);
    console.log(event.target.email);
    console.log(event.target.phone);
    console.log(event.target.password);
    */

    //console.log(event.target);
    let form = event.target.form;
    console.log("form: " + form);

    // How to get form data?  FormData object.
    // Note that formData is an array of arrays!  Important.
    let formData = new FormData(form);
    formData.append("todayDate", todayDate.toISOString());
    
    /*
    console.log("agreeFlag: " + agreeFlag);
    setAgreeFlag(1);
    console.log("agreeFlag after updating: " + agreeFlag);
    formData.append("agreeFlag", agreeFlag);

    setAgreeFlag(0);
    console.log("agreeFlag after reset: " + agreeFlag);
    */

    console.log("formData.entries: " + formData.entries);

    // Now create a form object with key/value pairs
    let formDataObj = Object.fromEntries(formData.entries());
    let formJSON = JSON.stringify(formDataObj);

    console.log("formData: " + formData.entries);
    console.log("formDataObj: " + formDataObj);
    console.log("formJSON: " + formJSON);

    //const endPoint = "https://webhook.site/e0f3501a-7dce-4061-83ee-d26c769ea29f";
    const endPoint = "https://visitor-trainer-cqckd6ced3a0bgch.centralus-01.azurewebsites.net/api/register";
    //const endPoint = "http://localhost:3001/api/register";

    fetch(
      endPoint,
      {
        method: 'POST',
        mode: 'cors',  //Cross-origin resource sharing; this setting only for dev to work across domains.
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: formJSON
      }
      
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      //return response.json(); // Assuming API returns JSON data
    })
    /*
    .then(data => {
      // Handle the response data
      console.log(data);
    })*/
    .catch(error => {
      // Handle errors
      console.error('There was a problem with the fetch operation: ', error);
    });

    // can now validate
    /*
    if(formDataObj.name === '') {
      alert("Name cannot be blank.");
    }
    */

    //alert(`The email you entered was: ${email}`);    
  };

  return (
    <div style={{ textAlign: 'center'}}>
      {/*<form method="post" action={"https://visitor-trainer-cqckd6ced3a0bgch.centralus-01.azurewebsites.net/api/register"}>*/}
      {/*<form method="post" action={"https://webhook.site/e0f3501a-7dce-4061-83ee-d26c769ea29f"}>*/}
      
      <form>
        <Table align='center' striped bordered hover cellPadding={2}>
          <tbody>
            <tr>
              <td />
                <td style={{maxWidth: 850}}>
                <ScreenHeader message={"Visitor Training Tracker"}/>
                </td>
              <td />
            </tr>
          </tbody>
        </Table>
        <Table align='center' striped bordered hover cellPadding={2}>
          <tbody>
            <tr>
              <td />
                <td style={{maxWidth: 850}}>
                  <h2>First time? Please Register.</h2>
                </td>
              <td />
            </tr>
          </tbody>
        </Table>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td />
              <td style={{maxWidth: 850}}>
                <br/>
                <br/>
                  <Display message={`Already have an account?`}/>
                  <Link to="/login">Please Log In</Link>
              </td>
              <td />
            </tr>
          </tbody>
        </Table>
        <br/>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td colSpan={3}>
                <div>
                <Table align='center' striped bordered hover cellPadding={5}>
                  <tbody>
                      <tr>
                        <td width={"40%"} align="right">
                          <label>
                              Email:
                          </label>
                        </td>
                        <td>
                          <input 
                              type="text"
                              value={email}
                              name="email" 
                              onChange={(e) => setEmail(e.target.value)}
                              //value={inputs.email || ""}
                              //onChange={handleChange}
                              width={"200px"}
                            />
                        </td>
                      </tr>
                      <tr>
                        <td width={"40%"} align="right">
                          <label>
                              Password:
                          </label>
                        </td>
                        <td>
                            <input 
                              type="password"
                              name="password"
                              onChange={(e) => setPassword(e.target.value)}
                              //value={inputs.last_name || ""}
                              //onChange={handleChange}
                              width={"200px"} 
                            />
                        </td>
                      </tr>
                      <tr>
                        <td width={"40%"} align="right">
                          <label>
                              Phone:
                          </label>
                        </td>
                        <td>
                          <input 
                              type="text"
                              name="phone"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              //value={inputs.phone || ""}
                              //onChange={handleChange}
                              width={"200px"} 
                            />
                        </td>
                      </tr>
                      <tr>
                        <td width={"40%"} align="right">
                          <label>
                              First Name:
                          </label>
                        </td>
                        <td>
                          <input 
                              type="text"
                              value={first_name}
                              name="first_name" 
                              onChange={(e) => setFirstName(e.target.value)}
                              //value={inputs.email || ""}
                              //onChange={handleChange}
                              width={"200px"}
                            />
                        </td>
                      </tr>
                      <tr>
                        <td width={"40%"} align="right">
                          <label>
                            Last Name:
                          </label>
                        </td>
                        <td>
                          <input 
                              type="text"
                              value={last_name}
                              name="last_name" 
                              onChange={(e) => setLastName(e.target.value)}
                              //value={inputs.email || ""}
                              //onChange={handleChange}
                              width={"200px"}
                            />
                        </td>
                      </tr>
                      <tr>
                        <td width={"40%"} align="right">
                          <label>
                              (Optional) Organization:
                          </label>
                        </td>
                        <td>
                          <input 
                              type="text"
                              name="org" 
                              value={org}
                              onChange={(e) => setOrg(e.target.value)}
                              //value={inputs.phone || ""}
                              //onChange={handleChange}
                              width={"200px"} 
                            />
                        </td>
                      </tr>
                      {/*
                      <tr>
                        <td colSpan={2}>
                          <button type="submit">Submit</button>
                        </td>
                      </tr>
                      */}
                  </tbody>
                </Table>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        
        <br/><br/>
        
        {/*button onClick={ postData }>Hello here</button>*/}
        <button onClick={handleFormSubmit}>Submit</button>
      </form>
      <br/>
      <br/>
        {/*message={`Submit`}*/}
    </div>
  );
}

export default RegisterPage;
