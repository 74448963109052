import React, { useState } from 'react';

const styles = {
  backgroundColor: "hsl(0, 100%, 0%)",
  color: "gold",
  padding: "10px 20px",
  borderRadius: "5px",
  border: "none",
  width: "350px"
}

const ScreenHeader = (props) => {
    const [count, setCount] = React.useState(0);
    return (
      <div>
        <p style={styles}>{props.message}</p>
      </div>
    );
  }

  export default ScreenHeader;