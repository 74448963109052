import React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SelectLocation from './pages/SelectLocation';
import Acknowledge from './pages/Acknowledge';
import About from './About';
import Approve from './pages/Approve';
import HomePage from './pages/HomePage';
import ProfilesPage from './pages/ProfilesPage';
import ProfilePage from './pages/ProfilePage';
import RegisterPage from './pages/RegisterPage';
import Success from './pages/Success';
import NotFoundPage from './pages/NotFoundPage';
import Login from './utils/Login';

// ... import other pages

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/login',
    element: <Login />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/register',
    element: <RegisterPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/acknowledge/:siteID',
    element: <Acknowledge />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/approve/:siteID',
    element: <Approve />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/success',
    element: <Success />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/choose',
    element: <SelectLocation />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/profiles',
    element: <ProfilesPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/profiles/:profileId',
    element: <ProfilePage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/about',
    element: <About />,
    errorElement: <NotFoundPage />,
  }

]);

function App () {
  
  return (
    <RouterProvider router={router}>
      {/* RouterProvider is built using the React ContextAPI. */}
      {/* This defers the entry point to our application to React Router */}
      {/* The entire application should be able to listen for changes in the route. */}
      {/* Using the router, we can define which components get rendered at each path. */}
      <div style={{ textAlign: 'center' }}>

      </div>
    </RouterProvider>
  );
};

export default App;