import { useParams } from "react-router-dom";
import { useState } from 'react';

export default function ProfilePage() {
    const [counter, setCounter] = useState(0);
    const incrementCounter = (incrementValue) => setCounter(counter + incrementValue);
    
    const params = useParams();

    return (
        <div>
            <h1>Profile Page {params.profileId}</h1>
        </div>
    );
}
