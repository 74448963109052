// This will need future updates for correct authorization to access the page.
import React from 'react';
import { useParams } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"; 
import ScreenHeader from '../ScreenHeader';
import Display from '../Display';
import CenteredTextContainer from '../CenteredTextContainer';
import Button from '../Button';
import ApprovalList from './ApprovalList';
// ... import other pages

const postData = (event) => {

  event.preventDefault();

  try {

    //let result = fetch("https://visitor-trainer-cqckd6ced3a0bgch.centralus-01.azurewebsites.net/api/records", {
    let result = fetch("https://webhook.site/5fbb042c-2885-4d73-8e85-a87f25eccb05", {
    method: 'post',
      mode: 'no-cors',  //Cross-origin resource sharing
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        "email": "john.doe@gmail.com",
        first_name: 'John',
        last_name: 'Doe',
        phone: '9992223333',
        recorded_datetime: '9/16/2024',
        ackowledge_flag: 1
      })
    });

    console.log(
      JSON.stringify({
        "email": "john.doe@gmail.com",
        first_name: 'John',
        last_name: 'Doe',
        phone: '9992223333',
        recorded_datetime: '9/16/2024',
        ackowledge_flag: 1
      })
    );

    console.log('Result: ' + result);
  
  } catch(e) {

    console.log(e);

  }

}
   
function Approve() {

  const params = useParams();

  return (
    <div style={{ textAlign: 'center'}}>
      {/*<form method="post" action={"https://visitor-trainer-cqckd6ced3a0bgch.centralus-01.azurewebsites.net/api/records"}>*/}
      {/*<form method="post" action={"https://webhook.site/5fbb042c-2885-4d73-8e85-a87f25eccb05"}>*/}
      <form>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td />
                <td style={{maxWidth: 850}}>
                <ScreenHeader message={"Visitor Training Tracker"}/>
                </td>
              <td />
            </tr>
          </tbody>
        </Table>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td />
                <td style={{maxWidth: 850}}>
                  <h1>Site: {params.siteID}</h1>
                </td>
              <td />
            </tr>
          </tbody>
        </Table>
        <Table align='center' style={{ margin: 0 }} striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td style={{ width: "100%" }}>
                <ApprovalList />
              </td>
            </tr>
          </tbody>
        </Table>
        
        <br/><br/>
        
        {/*button onClick={ postData }>Hello here</button>*/}
        <button type="submit">Submit</button>
      </form>
      <br/>
      <br/>
        {/*message={`Submit`}*/}
    </div>
  );
}

export default Approve;


/*
<script>
function getCurDateTime() {
  var now = moment().format(); // Format the moment as a string
  var momentInput = document.getElementById("currentMoment");
  momentInput.value = now;
}
</script>

</head>

<body>

<div class="container">

<h1>Acknowledgment Form</h1>

<form action="api/records" method="POST" onsubmit="return getCurDateTime();">

<div class="form-group">

  <label for="email">Email</label>

  <input type="text" class="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter Email" name="email">

</div>

<div class="form-group">

  <label for="phone">Phone</label>

  <input type="text" class="form-control" id="phone" aria-describedby="emailHelp" placeholder="Enter Phone" name="phone">

</div>

<div class="form-group">

    <label for="first_name">First Name</label>

    <input type="text" class="form-control" id="first_name" aria-describedby="emailHelp" placeholder="Enter First Name" name="first_name">

</div>

<div class="form-group">

    <label for="last_name">Last Name</label>

    <input type="text" class="form-control" id="last_name" aria-describedby="emailHelp" placeholder="Enter Last Name" name="last_name">

</div>

<div class="form-group">

  <input type="hidden" class="form-control" id="ackowledge_flag" aria-describedby="emailHelp" value="1" name="ackowledge_flag">

</div>

<div class="form-group">

  <input type="hidden" class="form-control" id="currentMoment" aria-describedby="emailHelp" value="" name="recorded_datetime">

</div>

<button type="submit" class="btn btn-primary">Submit</button>
*/